<!--  -->
<template>
  <div>
    <pHeader />
    <div
      class="banner"
      :style="{'background-image':'url('+require('@/assets/img/banner_query.png')+')'}"
    >
      <div class="txt-box">
        <h3>在线授权查询</h3>
        <p>企业版授权专用查询(开源版暂不提供)</p>
      </div>
    </div>
    <div class="content">
      <div class="inner">
        <div class="header-title">
          <div class="tit">商业授权查询系统</div>
           <div class="tips">请使用公司全称+证书编号进行查询，查询信息已进行脱敏处理，请放心食用。</div>
        </div>
        <div class="search layout-flex">
          <input type="text" v-model="userName" placeholder="输入您的企业名称" />
          <input type="text" v-model="code" placeholder="输入您的证书编号" />
          <div class="search-btn" @click="search">查询</div>
        </div>
        <!--        <div class="explain" v-if="showStatus">-->
        <div class="list" v-if="!empty">
          <div class="item" v-if="authorization.userName">
            <p class="tit">授权对象：{{authorization.userName}}</p>
            <p class="tips">授权产品：{{authorization.productName}}</p>
            <p class="tips">授权时间：{{authorization.createTime}}</p>
            <p class="tips">证书编号：{{authorization.authCode}}</p>
          </div>
        </div>
        <div class="list" v-else>
          <p class="red">*查询不到合作伙伴信息，请检查输入是否为正确的公司全称和证书编号</p>
        </div>
<!--        <pagination-->
<!--          :total="page.total"-->
<!--          :currentPage="page.current"-->
<!--          :pageSize="page.size"-->
<!--          v-if="showStatus"-->
<!--          @refreshData="refreshData"-->
<!--        ></pagination>-->
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
import { getAuthorization } from "@/api";
export default {
  data () {
    return {
      empty: false,
      showStatus: true,
      code: '',
      userName: '',
      authorization: {},
      page: {
        size: 5,
        current: 1,
        total: 0,
      }
    };
  },

  components: {},

  computed: {},
  created () {
    // this.showStatus = true
    // this.query()
  },
  methods: {
    refreshData (e) {
      this.page.size = e.size
      this.page.current = e.current
      this.query()
    },
    search () {
      this.code = this.code.trim()
      this.userName = this.userName.trim()
      if (this.code.trim() != '') {
        this.showStatus = false
      } else {
        this.showStatus = true
      }
      // this.page.current = 1
      this.query()
    },
    query () {
      getAuthorization({
        code: this.code,
        userName: this.userName,
      }).then(res => {
        this.authorization = res.data
        if (!this.authorization) {
          this.empty = true
        } else {
          this.empty = false
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.content {
  background: #f5f7fa;
  .inner {
    padding: 4rem 0;
    max-width: $minWidth;
    margin: 0 auto;
    .search {
      padding: 0 2rem;
      margin: 0 auto 4rem;
      max-width: 85rem;
      height: 4rem;
      font-size: 1.4rem;
      input {
        padding: 0 1rem;
        flex: 1;
        height: 100%;
        // border: 1px solid #eee;
        //border-top-left-radius: 0.5rem;
        //border-bottom-left-radius: 0.5rem;
        border-radius: .5em;
        border: 1px solid #ccc;
        margin-right: 5px;
        //&:first-child{
          //margin-right: 5px;
          //border-right: 1px solid #ccc;
        //}
      }
      .search-btn {
        width: 10rem;
        height: 100%;
        text-align: center;
        color: #fff;
        line-height: 4rem;
        background: #3981f7;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      @media (max-width: 750px) {
        height: auto;
        display: block;
        input{
          display: block;
          width: 100%;
          margin-bottom: 10px;
          height: 4rem;
        }
        .search-btn{
          width: 100%;
          display: block;
        }
      }
    }
    .list {
      padding: 0 2rem;
      margin: 0 auto 4rem;
      max-width: 85rem;
      .item {
        margin-bottom: 1rem;
        padding: 2rem;
        background: #fff;
        cursor: pointer;
        // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        &:hover {
          box-shadow: 0 4px 8px rgb(3 27 78 / 12%);
          .tit {
            color: #3981f7;
          }
        }
        .tit {
          display: inline-block;
          vertical-align: middle;
          font-size: 20px;
          line-height: 36px;
          font-weight: 500;
          color: #333;
          margin-right: 12px;
        }
        .tips {
          font-size: 14px;
          line-height: 22px;
          color: #666;
        }
      }
      .red {
        font-size: 14px;
        margin-left: 4px;
        color: red;
      }
    }
    .explain {
      padding: 2rem 3rem;
      margin: 0 auto;
      max-width: 65rem;
      background: #fff3cd;
      border-radius: 0.5rem;
      .name {
        margin-bottom: 1.5rem;
        color: #856404;
        font-size: 2rem;
      }
      p {
        margin-bottom: 2rem;
        color: #856404;
        font-size: 1.6rem;
      }
      .red {
        color: #ff0000;
        font-weight: bold;
      }
    }
  }
}
</style>
